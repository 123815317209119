// import '@styles/app.scss';
import Controller from '@scripts/controllers/Controller';

/**
 * Front controller
 */
export default class extends Controller {
  boot() {
    this._store.startEvents();
  }
}
