import Store from '@scripts/Store';

/**
 * Controller
 */
export default class {
  /**
   * @type {Store}
   */
  _store;

  /**
   * @param {Store} store
   */
  constructor(store) {
    this._store = store;
  }
}
