/**
 * Data Layer event
 *
 * @abstract
 */
export default class {
  /**
   * Start data collection (e.g.: start listeners)
   *
   * @abstract
   *
   * @return {void}
   */
  start() {
    throw new Error('Event: initListners must be implemented');
  }

  /**
   * Stop data collection (e.g.: stop listeners)
   *
   * @abstract
   *
   * @return {void}
   */
  stop() {
    throw new Error('Event: initListners must be implemented');
  }

  /**
   * Reload data collection
   *
   * @return {void}
   */
  refresh() {
    this.stop();
    this.start();
  }
}
