import DLClick from '@scripts/events/Click';
import DLEvent from '@scripts/events/Event';
import DLForm from '@scripts/events/Form';
import DLVideo from '@scripts/events/Video';
import { isTrue } from '@scripts/helpers';

/**
 * Plugin storage
 */
export default class {
  /**
   * @type {boolean} If it's the administration
   */
  admin;

  /**
   * @type {string} URL used to send AJAX request
   */
  ajaxUrl;

  /**
   * @type {string} Site language code
   */
  lang;

  /**
   * @type {boolean} If compatibility with Contact Form 7 is enabled
   */
  cf7;

  /**
   * Data Layer events
   *
   * @type {Object<string, DLEvent}
   */
  events = {};

  /**
   * @param {Object} options Configuration object
   * @param {boolean|string|integer} options.admin If it's the administration (e.g.: true | 1 | '1')
   * @param {string} options.ajaxUrl URL used to send AJAX request
   * @param {string} options.lang Language code
   * @param {boolean|string|integer} options.cf7 If compatibility with Contact Form 7 is enabled (e.g.: true | 1 | '1')
   */
  constructor({ admin = false, ajaxUrl = '', lang = '', cf7 = false }) {
    this.admin = isTrue(admin);
    this.ajaxUrl = ajaxUrl;
    this.lang = lang;
    this.cf7 = isTrue(cf7);
    this.events = {
      click: new DLClick(),
      form: new DLForm(this.cf7),
      video: new DLVideo(),
    };
  }

  /**
   * Start Data Layer events
   *
   * @return {void}
   */
  startEvents() {
    for (var key in this.events) {
      this.events[key].start();
    }
  }
}
