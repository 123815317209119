import DLEvent from '@scripts/events/Event';

/**
 * Video event callbacks
 *
 * @typedef {Object} VideoEventCallbacks
 * @property {?Function} start - Video start callback
 */

/**
 * Data Layer Even - Click
 *
 * Capture clicks on links and buttons and add data to the Data Layer
 */
export default class extends DLEvent {
  /**
   * Listener callbacks
   *
   * @private
   *
   * @type {VideoEventCallbacks}
   */
  #callbacks = {
    start: null,
  };

  /**
   * List of videos URL for which playback has been triggered at least once
   *
   * @private
   *
   * @type {string[]}
   */
  #started = [];

  /**
   * Pushes the video start event to first playback
   *
   * @private
   *
   * @param {Event} e Play event
   *
   * @return {void}
   */
  #handlerVideoStart(e) {
    if (
      e.target &&
      e.target instanceof HTMLVideoElement &&
      !this.#started.includes(e.target.src)
    ) {
      this.#pushVideoStart(e.target);
    }
  }

  /**
   * Pushes the video start event
   *
   * @private
   *
   * @param {HTMLVideoElement} video Video element
   *
   * @return {void}
   */
  #pushVideoStart(video) {
    this.#started.push(video.src);
    window.dataLayer.push({
      event: 'video_start',
      video_title: video?.title || '',
      video_url: video.src,
      video_category: 'Wordpress',
    });
  }

  start() {
    // listener: video start
    this.#callbacks.start = (e) => this.#handlerVideoStart(e);

    // WordPress Video: 'play' event
    document
      .querySelectorAll('video')
      .forEach((video) =>
        video.addEventListener('play', this.#callbacks.start)
      );

    // Theme Starter: video embed
    const embedModal = document.querySelector('.modal-embed');
    document.querySelectorAll('.video-player').forEach((container) => {
      // modal
      // listen 'play' event when modal is displayed
      if (embedModal) {
        container.addEventListener('embed_display_modal', () => {
          const video = embedModal.querySelector('video');
          if (video) {
            video.addEventListener('play', this.#callbacks.start);
          }
        });
      }

      // inline
      // listen 'play' event when video is displayed
      container.addEventListener('embed_display_inline', () => {
        const video = container.querySelector('video');
        if (video) {
          video.addEventListener('play', this.#callbacks.start);
        }
      });
    });
  }

  stop() {
    document
      .querySelectorAll('video')
      .forEach((video) =>
        video.removeEventListener('play', this.#callbacks.start)
      );
  }
}
