/**
 * Helper functions
 */

/**
 * Try to parse a JSON string
 *
 * @param {string} json Stringified JSON
 *
 * @return {Object|Array|string|number|boolean|null} The parsed JSON or null if invalid
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse
 */
export function jsonParse(json) {
  try {
    return JSON.parse(json);
  } catch {
    return null;
  }
}

/**
 * @param {integer|string|boolean} value Value to check
 *
 * @return {boolean} If equal true | 1 | '1'
 */
export function isTrue(value) {
  return value === true || value === 1 || value === '1';
}

/**
 * @param {integer|string|boolean} value Value to check
 *
 * @return {boolean} If equal false | 0 | '0'
 */
export function isFalse(value) {
  return value === false || value === 0 || value === '0';
}
