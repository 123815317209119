/**
 * App script
 */
import '@images/i-menu.png';
import '@styles/app.scss';

import Admin from '@scripts/controllers/Admin';
import Front from '@scripts/controllers/Front';
import Store from '@scripts/Store';

document.addEventListener('DOMContentLoaded', () => {
  // create storage object
  const store = new Store(window.abbottDatalayer || {});

  // find controller based on the environment
  const controller = store.admin ? new Admin(store) : new Front(store);
  controller.boot();
});
