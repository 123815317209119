import DLEvent from '@scripts/events/Event';

/**
 * Data Layer Even - Click
 *
 * Capture clicks on links and buttons and add data to the Data Layer
 */
export default class extends DLEvent {
  /**
   * Click handler
   *
   * @private
   *
   * @type {?Function}
   */
  #clickCallback;

  /**
   * Extract data to be pushed into the Data Layer of an HTML link or button
   *
   * @param {HTMLAnchorElement|HTMLButtonElement} element HTML element
   *
   * @return {Object}
   */
  #extractData(element) {
    const isLink =
      element instanceof HTMLAnchorElement && element.hasAttribute('href');
    let category = '';

    if (isLink) {
      // based on URL
      let domain = new URL(element.href);
      switch (domain.host) {
        case 'play.google.com':
          // Google Play Store
          category = 'android';
          break;
        case 'apps.apple.com':
          // Apple Store
          category = 'apple';
          break;
        case 'calendly.com':
          // Calendly
          category = 'calendly';
          break;
      }

      if (category) {
        return {
          event: 'click_app',
          link_category: category,
        };
      }
    }

    if (
      element.classList.contains('menu-item') ||
      element.closest('.menu-item') ||
      // product template
      element.closest('.main-menu')
    ) {
      // menu link
      category = 'menu';
    } else if (element.closest('form')) {
      // form link
      category = 'form';
    } else {
      // default
      category = isLink ? 'link' : 'button';
    }

    return {
      event: 'click_cta',
      link_text: element.textContent.trim(),
      link_category: category,
      ...(isLink ? { link_url: element.href } : {}),
    };
  }

  /**
   * Get a link or a button from targeted element
   *
   * @private
   *
   * @param {HTMLElement} element Target element
   *
   * @return {HTMLAnchorElement|HTMLButtonElement|null}
   */
  #getValidElement(element) {
    if (!element) {
      return null;
    }

    // it's a link or a button
    if (
      element instanceof HTMLAnchorElement ||
      element instanceof HTMLButtonElement
    ) {
      return element;
    }

    // it's a child of a link or a button
    return element.closest('a, button');
  }

  /**
   * Push data into Data Layer after clicking on a link or a button
   *
   * @private
   *
   * @param {Event} event Click event
   *
   * @return {void}
   */
  #handlerClick(event) {
    const element = this.#getValidElement(event.target);
    if (element) {
      window.dataLayer.push(this.#extractData(element));
    }
  }

  start() {
    this.#clickCallback = (e) => this.#handlerClick(e);
    document.addEventListener('click', this.#clickCallback);
  }

  stop() {
    if (this.#clickCallback) {
      document.removeEventListener('click', this.#clickCallback);
      this.#clickCallback = null;
    }
  }
}
