import DLEvent from '@scripts/events/Event';
import { jsonParse } from '@scripts/helpers';

/**
 * Form event callbacks
 *
 * @typedef {Object} FormEventCallbacks
 * @property {?Function} start - Form start callback
 * @property {?Function} step - Form step callback
 * @property {?Function} result - Form result callback
 */

/**
 * Contact Form 7 Store
 *
 * @typedef {Object} CF7Store
 * @property {boolean} active - If compatibility with Contact Form 7 is enabled
 * @property {Object<string, string>} forms - Form identifiers indexed by ID
 * @property {?Function} callback - Submit listener callback
 */

/**
 * Data Layer Event - Form
 *
 * Manages events on forms:
 *  + Form start: the user arrives at the first step of the form
 *  + Form step: user arrives at another form step
 *  + Form result: user has successfully submitted the form
 */
export default class extends DLEvent {
  /**
   * @private
   *
   * @type {CF7Store}
   */
  #cf7Store = {
    active: false,
    forms: {},
    callback: null,
  };

  /**
   * Listener callbacks
   *
   * @private
   *
   * @type {FormEventCallbacks}
   */
  #callbacks = {
    start: null,
    step: null,
    result: null,
  };

  /**
   * @param {boolean} cf7 If must be compatible with Contact Form 7
   */
  constructor(cf7 = false) {
    super();

    this.#cf7Store.active = cf7;
  }

  /**
   * Push 'form_start' in Data Layer
   *
   * @private
   *
   * @param {string} name Form identifier
   *
   * @return {void}
   */
  #pushFormStart(name) {
    window.dataLayer.push({
      event: 'form_start',
      form_name: name,
    });
  }

  /**
   * Push 'form_step' in Data Layer
   *
   * @private
   *
   * @param {string} name Form identifier
   * @param {string|integer} step Form step
   *
   * @return {void}
   */
  #pushFormStep(name, step) {
    window.dataLayer.push({
      event: 'form_step',
      form_name: name,
      form_step: step,
    });
  }

  /**
   * Push 'generate_lead' in Data Layer
   *
   * @private
   *
   * @param {string} name Form identifier
   * @param {string|number|Array|Object} result Form result
   *
   * @return {void}
   */
  #pushFormResult(name, result) {
    // check if 'result' is a stringified JSON
    if (typeof result === 'string') {
      const json = jsonParse(result);
      if (json) {
        result = json;
      }
    }

    window.dataLayer.push({
      event: 'generate_lead',
      form_name: name,
      form_result: result,
    });
  }

  /**
   * Detects whether there is an event to push based on HTML attributes
   *  + data-abbott-form - Attribute with form identifier
   *  + data-abbott-form-step - Attribute with form step
   *  + data-abbott-form-result - Attribute with form result
   *
   * @param {HTMLElement} element HTML element with attributes
   *
   * @private
   *
   * @return {void}
   */
  #pushByAttributes(element) {
    const name = element.dataset.abbottForm;
    if (!name) {
      return;
    }

    if (element.dataset.abbottFormStep) {
      // form step
      this.#pushFormStep(name, element.dataset.abbottFormStep);
    } else if (element.dataset.abbottFormResult) {
      // form result
      this.#pushFormResult(name, element.dataset.abbottFormResult);
    } else {
      // form start
      this.#pushFormStart(name);
    }
  }

  /**
   * Add event listeners
   *  + abbott-form-start - Form start event
   *  + abbott-form-step - Form step event
   *  + abbott-form-result - Form result event
   *
   * @private
   *
   * @return {void}
   */
  #registerListeners() {
    // listener: form start
    this.#callbacks.start = (e) => this.#pushFormStart(e.detail.name);
    document.addEventListener('abbott-form-start', this.#callbacks.start);

    // listener: form step
    this.#callbacks.step = (e) =>
      this.#pushFormStep(e.detail.name, e.detail.step);
    document.addEventListener('abbott-form-step', this.#callbacks.step);

    // listener: form result
    this.#callbacks.result = (e) =>
      this.#pushFormResult(e.detail.name, e.detail.result);
    document.addEventListener('abbott-form-result', this.#callbacks.result);
  }

  /**
   * Remove event listeners
   *
   * @private
   *
   * @return {void}
   */
  #unregisterListeners() {
    // listener: form start
    if (this.#callbacks.start) {
      document.removeEventListener('abbott-form-start', this.#callbacks.start);
    }

    // listener: form step
    if (this.#callbacks.step) {
      document.removeEventListener('abbott-form-step', this.#callbacks.step);
    }

    // listener: form result
    if (this.#callbacks.result) {
      document.removeEventListener(
        'abbott-form-result',
        this.#callbacks.result
      );
    }
  }

  /**
   * Manages 'form_start' and 'generate_lead' events for valid Contact Form 7 forms
   *
   * @private
   *
   * @return {void}
   *
   * @link https://contactform7.com/dom-events/#coding-event-handler
   */
  #registerCf7() {
    // Form start
    document.querySelectorAll('.wpcf7').forEach((form) => {
      const input = form.querySelector('.abbott-form');
      if (input && input.value) {
        this.#cf7Store.forms[form.id] = input.value;
        this.#pushFormStart(input.value);
      }
    });

    // Form result
    if (Object.keys(this.#cf7Store.forms).length > 0) {
      this.#cf7Store.callback = (e) => {
        let name = this.#cf7Store.forms[e.detail.unitTag] || '';
        if (name) {
          this.#pushFormResult(name, 1);
        }
      };
      document.addEventListener('wpcf7submit', this.#cf7Store.callback, false);
    }
  }

  #unregisterCf7() {
    if (this.#cf7Store.callback) {
      document.removeEventListener('wpcf7submit', this.#cf7Store.callback);
      this.#cf7Store.callback = null;
    }
  }

  start() {
    // automatic
    document
      .querySelectorAll('[data-abbott-form]')
      .forEach((form) => this.#pushByAttributes(form));

    // dynamic
    this.#registerListeners();

    // contact form 7
    if (this.#cf7Store.active) {
      this.#registerCf7();
    }
  }

  stop() {
    this.#unregisterListeners();
    this.#unregisterCf7();
  }
}
